import React from 'react';
import PropTypes from 'prop-types';

const WeddingIcon = ({ size }) => (
    <svg width={size} height={size} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
            <g>
                <path
                    d="M350.87,144.228c-34.533,0-67.206,10.662-94.87,30.883c-17.24-12.601-36.424-21.486-56.793-26.394l43.291-49.612
    c0.015-0.017,0.025-0.037,0.039-0.054c0.257-0.299,0.486-0.619,0.693-0.955c0.033-0.054,0.066-0.107,0.098-0.161
    c0.192-0.331,0.36-0.676,0.501-1.037c0.022-0.056,0.041-0.112,0.062-0.169c0.132-0.363,0.242-0.737,0.317-1.123
    c0.007-0.035,0.01-0.07,0.016-0.104c0.065-0.361,0.102-0.73,0.114-1.106c0.002-0.05,0.007-0.099,0.007-0.149
    c0-0.027,0.004-0.054,0.004-0.081c0-0.359-0.034-0.71-0.082-1.054c-0.01-0.068-0.019-0.134-0.031-0.202
    c-0.064-0.381-0.154-0.753-0.274-1.111c-0.016-0.047-0.035-0.093-0.051-0.14c-0.136-0.383-0.296-0.755-0.489-1.106
    c-0.008-0.015-0.018-0.029-0.026-0.044c-0.208-0.373-0.447-0.726-0.714-1.056c-0.008-0.01-0.013-0.021-0.021-0.031l-0.028-0.034
    l-33.483-41.102c-1.427-1.752-3.568-2.769-5.827-2.769h-25.498h-33.395h-25.496c-2.26,0-4.4,1.017-5.827,2.769L79.625,89.382
    l-0.028,0.034c-0.008,0.01-0.013,0.021-0.021,0.031c-0.267,0.33-0.505,0.683-0.714,1.056c-0.008,0.015-0.018,0.029-0.026,0.044
    c-0.193,0.352-0.353,0.724-0.489,1.106c-0.017,0.047-0.036,0.093-0.051,0.14c-0.119,0.359-0.209,0.731-0.274,1.111
    c-0.011,0.067-0.021,0.134-0.031,0.202c-0.049,0.346-0.082,0.695-0.082,1.054c0,0.028,0.004,0.054,0.004,0.081
    c0.001,0.05,0.006,0.099,0.007,0.149c0.011,0.377,0.049,0.746,0.114,1.106c0.006,0.035,0.009,0.07,0.016,0.104
    c0.075,0.387,0.185,0.761,0.317,1.123c0.021,0.057,0.04,0.113,0.062,0.169c0.141,0.361,0.309,0.706,0.501,1.037
    c0.031,0.054,0.065,0.107,0.098,0.161c0.207,0.337,0.438,0.656,0.693,0.955c0.015,0.017,0.024,0.037,0.039,0.054l43.339,49.67
    C52.547,165.911,0,229.608,0,305.358c0,88.848,72.283,161.13,161.129,161.13c34.533,0,67.206-10.662,94.871-30.883
    c27.664,20.22,60.336,30.883,94.871,30.883c88.847,0,161.129-72.283,161.129-161.13S439.717,144.228,350.87,144.228z
    M199.753,60.543l21.265,26.104h-24.456l-8.416-26.104H199.753z M196.444,101.678h23.858l-38.285,43.877
    c-0.232-0.029-0.461-0.062-0.691-0.091L196.444,101.678z M149.906,60.542h22.447l8.416,26.104H141.49L149.906,60.542z
    M180.542,101.679l-14.717,42.624c-1.561-0.044-3.125-0.074-4.694-0.074c-0.964,0-1.953,0.015-2.958,0.037
    c-0.579,0.01-1.159,0.019-1.738,0.036l-14.717-42.623H180.542z M122.507,60.542h11.605l-8.415,26.104h-24.455L122.507,60.542z
    M101.958,101.679h23.857l15.128,43.811c-0.227,0.029-0.454,0.058-0.68,0.087L101.958,101.679z M161.129,451.455
    c-80.558,0-146.097-65.539-146.097-146.097c0-72.876,53.636-133.457,123.504-144.349c1.744-0.271,3.495-0.507,5.252-0.715
    c0.219-0.026,0.44-0.051,0.66-0.076c2.36-0.269,4.728-0.464,7.097-0.619c0.037-0.002,0.073,0.005,0.11,0.002
    c1.748-0.112,3.35-0.194,4.86-0.251c1.539-0.049,3.078-0.09,4.613-0.09c3.075,0,6.128,0.195,9.449,0.409
    c0.162,0.01,0.324,0.015,0.484,0.015c0.121,0,0.239-0.022,0.359-0.028c4.015,0.302,8.104,0.774,12.341,1.433
    c0.386,0.06,0.773,0.089,1.156,0.089c0.016,0,0.033-0.002,0.049-0.003c24.16,3.926,46.775,13.846,66.387,29.266
    c2.02,1.588,4,3.233,5.938,4.929c1.887,1.652,3.731,3.356,5.533,5.105c0.973,0.945,1.936,1.901,2.883,2.872
    c2.702,2.77,5.295,5.647,7.769,8.621c0,0,0,0,0.001,0.001c21.763,26.151,33.749,59.317,33.749,93.388
    c0,31.637-10.335,62.494-29.234,87.678c-0.04-0.034-0.082-0.066-0.123-0.099c-1.04-0.869-2.066-1.754-3.076-2.659
    c-0.194-0.174-0.383-0.357-0.576-0.533c-0.809-0.735-1.61-1.477-2.398-2.235c-0.345-0.332-0.68-0.672-1.021-1.009
    c-0.626-0.617-1.25-1.238-1.861-1.87c-0.37-0.383-0.734-0.772-1.098-1.159c-0.57-0.605-1.136-1.216-1.693-1.834
    c-0.17-0.188-0.337-0.381-0.506-0.57c12.13-16.726,20.093-36.159,23.13-56.571c0.61-4.106-2.222-7.93-6.327-8.54
    c-4.111-0.613-7.93,2.223-8.54,6.327c-2.465,16.566-8.619,32.398-17.918,46.323c-12.55-18.714-19.138-40.386-19.138-63.25
    s6.589-44.536,19.138-63.249c10.283,15.382,16.667,32.985,18.564,51.467c0.398,3.869,3.662,6.749,7.468,6.749
    c0.257,0,0.516-0.013,0.777-0.04c4.13-0.424,7.133-4.116,6.709-8.245c-2.531-24.66-12.09-47.948-27.64-67.346
    c-6.258-7.806-13.399-14.862-21.269-21.02c-1.124-0.88-2.264-1.742-3.417-2.583c-22.197-16.215-48.494-24.787-76.048-24.787
    c-31.054,0-61.064,11.19-84.5,31.507c-3.136,2.719-3.474,7.466-0.756,10.602c2.719,3.136,7.466,3.474,10.602,0.756
    c20.703-17.949,47.215-27.833,74.653-27.833c21.723,0,42.551,6.044,60.716,17.518c-20.754,27.744-32.106,61.693-32.106,96.503
    c0,34.809,11.352,68.759,32.106,96.503c-18.165,11.474-38.993,17.518-60.716,17.518c-62.872,0-114.021-51.15-114.021-114.021
    c0-23.382,7.024-45.85,20.312-64.976c2.369-3.408,1.525-8.092-1.884-10.461c-3.409-2.37-8.092-1.525-10.46,1.884
    c-15.047,21.656-23,47.09-23,73.553c0,71.16,57.893,129.053,129.053,129.053c25.234,0,49.411-7.187,70.352-20.852
    c0.184,0.203,0.376,0.4,0.561,0.601c0.495,0.54,0.996,1.075,1.499,1.609c0.481,0.512,0.963,1.022,1.452,1.528
    c0.511,0.529,1.029,1.052,1.547,1.574c0.494,0.498,0.988,0.995,1.488,1.486c0.525,0.515,1.056,1.023,1.587,1.531
    c0.507,0.484,1.013,0.969,1.526,1.447c0.537,0.5,1.081,0.993,1.625,1.485c0.362,0.328,0.716,0.663,1.079,0.987
    C219.485,442.619,191.079,451.455,161.129,451.455z M350.87,451.455c-33.082,0-64.282-10.782-90.225-31.18
    c-8.08-6.355-15.525-13.597-22.125-21.528c-21.763-26.153-33.749-59.318-33.749-93.389c0-31.636,10.334-62.493,29.234-87.678
    c0.039,0.032,0.079,0.063,0.118,0.095c1.042,0.869,2.069,1.756,3.08,2.662c0.201,0.18,0.396,0.369,0.596,0.551
    c0.802,0.729,1.597,1.465,2.378,2.217c0.349,0.336,0.689,0.681,1.034,1.022c0.62,0.612,1.239,1.228,1.845,1.854
    c0.376,0.388,0.744,0.783,1.113,1.176c0.563,0.599,1.123,1.202,1.674,1.813c0.173,0.192,0.344,0.388,0.515,0.582
    c-15.867,21.908-24.543,48.552-24.543,75.707c0,29.237,10.057,57.884,28.32,80.662c7.151,8.92,15.457,16.862,24.685,23.604
    c22.197,16.215,48.495,24.787,76.049,24.787c33.75,0,65.671-12.944,89.882-36.446c2.978-2.891,3.049-7.649,0.157-10.628
    c-2.891-2.979-7.649-3.048-10.628-0.157c-21.39,20.764-49.592,32.199-79.412,32.199c-21.723,0-42.551-6.045-60.717-17.519
    c20.754-27.743,32.106-61.693,32.106-96.503s-11.352-68.76-32.106-96.503c18.166-11.474,38.994-17.518,60.717-17.518
    c62.872,0,114.021,51.15,114.021,114.021c0,21.164-5.838,41.823-16.884,59.742c-2.179,3.534-1.079,8.164,2.454,10.342
    c3.534,2.179,8.164,1.079,10.342-2.454c12.509-20.292,19.121-43.678,19.121-67.63c0-71.16-57.893-129.053-129.053-129.053
    c-25.233,0-49.411,7.187-70.352,20.852c-0.179-0.197-0.365-0.389-0.545-0.585c-0.509-0.555-1.023-1.105-1.54-1.654
    c-0.468-0.497-0.936-0.993-1.41-1.484c-0.526-0.544-1.058-1.082-1.592-1.619c-0.479-0.482-0.957-0.964-1.442-1.44
    c-0.541-0.531-1.088-1.055-1.637-1.579c-0.491-0.469-0.981-0.938-1.477-1.4c-0.556-0.518-1.12-1.029-1.685-1.539
    c-0.348-0.315-0.687-0.637-1.037-0.948c24.361-16.81,52.766-25.646,82.717-25.646c80.558,0,146.097,65.539,146.097,146.097
    C496.968,385.916,431.429,451.455,350.87,451.455z"
                />
            </g>
        </g>
    </svg>
);

WeddingIcon.propTypes = {
    size: PropTypes.number,
};

export default WeddingIcon;
