import React from 'react';
import PropTypes from 'prop-types';

const CocktailIcon = ({ size }) => (
    <svg width={size} height={size} version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 514">
        <g transform="translate(0.000000,514.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path
                d="M4718 4790 c-142 -45 -177 -77 -223 -203 -22 -58 -31 -55 -15 4 34
127 -55 233 -166 197 -48 -16 -84 -67 -84 -117 0 -57 13 -67 43 -37 35 35 62
33 103 -8 29 -29 34 -41 34 -79 0 -37 -4 -48 -26 -62 -14 -9 -23 -22 -20 -30
3 -8 -5 -16 -19 -19 -46 -12 -75 18 -55 55 13 23 2 24 -28 3 -31 -22 -26 -70
9 -96 24 -18 28 -19 62 -4 21 8 37 20 37 26 0 6 5 8 11 5 16 -10 -24 -35 -90
-56 -47 -15 -52 -19 -33 -25 27 -8 28 -16 2 -39 -16 -15 -34 -17 -111 -14 -63
3 -93 0 -96 -7 -5 -15 41 -23 137 -25 l75 -2 1 -98 c0 -103 5 -119 27 -100 11
9 12 22 6 58 -10 52 -2 141 14 161 8 11 13 9 22 -9 12 -21 13 -21 25 16 l13
38 12 -31 c13 -29 41 -52 65 -52 15 0 60 46 60 62 0 9 -3 9 -12 0 -31 -31 -75
0 -64 44 4 15 10 24 13 21 9 -10 43 12 43 28 0 7 14 16 30 20 43 9 122 -30
134 -67 11 -33 -5 -71 -38 -86 -20 -10 -20 -10 7 -21 37 -15 61 -14 102 3 41
17 62 48 70 101 5 32 2 47 -17 75 -36 54 -80 72 -158 66 l-65 -5 56 23 c80 33
107 53 135 102 21 36 73 182 74 207 0 10 -3 9 -102 -23z m-284 -325 c-4 -8
-11 -12 -16 -9 -6 4 -5 10 3 15 19 12 19 11 13 -6z m46 -30 c-7 -8 -17 -15
-23 -15 -6 0 -2 7 9 15 25 19 30 19 14 0z m-89 -92 c-12 -20 -14 -14 -5 12 4
9 9 14 11 11 3 -2 0 -13 -6 -23z"
            />
            <path
                d="M310 4805 c0 -13 60 -186 73 -210 24 -47 68 -83 134 -109 61 -24 104
    -45 91 -46 -4 0 -19 5 -35 11 -41 15 -127 13 -161 -5 -48 -25 -72 -63 -72
-113 0 -55 22 -98 60 -118 30 -16 92 -19 123 -7 16 7 14 10 -13 30 -54 40 -36
103 39 136 40 19 118 16 106 -4 -6 -10 24 -42 34 -35 5 2 13 -7 19 -20 10 -21
8 -29 -8 -45 -16 -16 -25 -18 -40 -10 -28 15 -31 13 -18 -15 21 -46 78 -47
112 -2 l21 28 -23 42 -22 41 22 20 22 21 -18 -22 c-17 -21 -18 -25 -3 -40 8
-10 22 -43 30 -73 13 -46 17 -51 22 -32 4 13 13 21 19 19 7 -2 11 -40 12 -104
1 -103 9 -137 25 -112 5 8 9 53 9 102 0 71 3 87 15 87 8 0 15 4 15 10 0 5 41
11 90 12 121 4 114 23 -8 23 -72 0 -95 3 -102 15 -7 11 -5 19 7 27 20 16 19
17 -39 33 -26 7 -53 20 -60 29 -12 14 -11 15 5 2 24 -19 75 -16 98 5 24 22 24
66 1 87 -27 25 -39 21 -34 -12 5 -33 -6 -43 -52 -45 -29 -1 -32 1 -28 22 3 17
-1 25 -16 29 -46 12 -36 123 15 163 36 28 70 25 103 -10 32 -34 40 -26 40 41
0 80 -53 129 -138 129 -83 0 -144 -97 -122 -196 15 -66 6 -65 -19 4 -28 75
-69 125 -123 150 -44 21 -208 73 -208 67z m430 -361 c0 -8 -19 -13 -24 -6 -3
5 1 9 9 9 8 0 15 -2 15 -3z m-55 -43 c3 -5 2 -12 -3 -15 -5 -3 -9 1 -9 9 0 17
3 19 12 6z"
            />
            <path
                d="M2945 4293 c-50 -26 -84 -64 -126 -139 -40 -74 -69 -100 -69 -64 0
27 -26 25 -53 -3 -24 -25 -114 -67 -145 -67 -25 0 -58 -28 -66 -56 -8 -28 45
-84 80 -84 30 0 30 -3 -3 -46 l-26 -35 -26 24 c-58 55 -122 61 -217 23 -32
-13 -76 -27 -97 -31 -20 -4 -40 -11 -42 -16 -3 -4 4 -26 15 -49 20 -40 45 -51
67 -29 5 5 39 17 73 25 62 14 65 14 114 -10 28 -14 58 -26 66 -29 8 -2 -25 -7
-74 -12 -101 -8 -112 -16 -183 -125 -19 -30 -44 -67 -54 -82 -11 -15 -19 -32
-19 -37 0 -16 83 -56 88 -42 2 7 15 25 29 41 13 16 22 34 19 39 -7 11 41 44
53 37 5 -3 14 -26 21 -51 11 -39 10 -48 -4 -69 -13 -20 -14 -37 -8 -85 8 -59
8 -60 -18 -69 -33 -12 -131 -111 -177 -179 -20 -29 -39 -53 -42 -53 -9 0 -131
124 -131 133 0 4 7 7 15 7 25 0 17 15 -33 62 -31 29 -61 47 -85 52 -47 9 -47
22 0 31 88 16 101 50 18 43 -63 -5 -71 2 -20 21 79 28 18 42 -70 16 -47 -15
-50 -14 -69 5 -23 23 -21 23 59 21 28 -1 79 7 115 17 54 16 65 23 64 41 0 11
6 21 13 21 23 0 90 38 97 55 9 26 7 34 -14 55 -29 29 -60 25 -136 -20 -37 -22
-70 -38 -72 -35 -5 5 130 155 140 155 4 0 19 21 34 46 70 120 5 225 -149 241
-89 9 -207 -41 -207 -88 0 -22 8 -24 35 -6 22 15 55 28 55 23 0 -3 -16 -14
-36 -27 -20 -12 -49 -42 -65 -66 -49 -78 -17 -93 89 -43 35 17 65 30 67 30 1
0 0 -20 -3 -45 -4 -43 8 -75 29 -75 4 0 22 27 39 59 18 33 43 70 57 83 l26 23
-6 -43 c-3 -23 -13 -54 -22 -68 -22 -33 -75 -66 -100 -60 -93 21 -85 24 -179
-65 -48 -45 -89 -80 -91 -77 -3 2 0 20 5 38 12 43 -3 102 -32 130 -28 26 -46
25 -50 -2 -2 -16 -10 -22 -33 -23 -26 -1 -61 28 -280 239 l-250 240 243 -245
c133 -134 242 -248 242 -252 0 -5 -11 -23 -24 -40 -13 -18 -34 -62 -47 -98
l-23 -67 -27 29 c-20 22 -36 29 -63 29 -43 0 -45 -10 -11 -65 21 -34 25 -52
25 -127 0 -48 -5 -108 -12 -133 l-12 -45 -21 26 c-22 28 -113 58 -129 43 -6
-5 -5 -19 2 -34 18 -40 15 -45 -29 -45 -37 0 -40 -2 -33 -22 4 -13 13 -32 20
-42 12 -18 10 -22 -16 -38 -17 -10 -30 -22 -30 -27 0 -5 26 -24 58 -41 65 -37
102 -68 102 -86 0 -7 -19 -26 -42 -43 -89 -61 -134 -129 -124 -185 4 -23 12
-36 23 -36 17 0 18 4 -17 -155 -16 -75 -20 -115 -14 -160 9 -82 55 -196 98
-247 20 -23 36 -45 36 -49 0 -13 121 -75 220 -115 232 -91 278 -121 287 -191
4 -35 1 -42 -41 -83 l-46 -44 0 30 c0 27 -49 121 -70 134 -5 3 -10 18 -12 34
l-3 28 -25 -20 c-20 -17 -25 -29 -25 -69 0 -26 8 -63 17 -80 9 -18 13 -33 8
-33 -16 0 -47 49 -68 108 -12 32 -30 66 -39 74 -20 17 -82 43 -88 37 -2 -2 5
-47 16 -99 11 -58 15 -96 9 -98 -34 -12 24 -110 90 -153 l50 -33 -102 -105
c-57 -58 -163 -168 -236 -243 l-132 -137 0 77 c0 86 -8 124 -23 119 -6 -2 -11
-50 -11 -123 l-1 -120 118 2 c131 1 177 18 84 29 -31 4 -76 5 -101 3 -25 -2
-46 0 -46 3 0 4 21 26 48 49 26 24 138 131 250 238 111 107 208 192 215 189
20 -8 136 12 170 29 87 44 154 154 137 225 -4 19 -11 41 -14 49 -4 10 20 41
74 95 44 44 80 73 80 65 0 -8 -9 -69 -20 -134 -17 -101 -18 -136 -10 -225 5
-58 14 -122 21 -141 39 -111 191 -264 421 -422 163 -112 287 -188 306 -188 6
0 26 13 45 29 18 17 94 64 168 107 222 127 302 193 356 297 75 144 104 223
114 307 10 78 6 191 -8 247 -4 17 11 8 57 -37 34 -33 68 -60 74 -60 13 0 72
-57 65 -64 -2 -2 -22 -13 -44 -24 -66 -33 -155 -141 -155 -187 0 -9 -9 -34
-20 -56 -17 -33 -20 -50 -14 -101 11 -95 54 -128 152 -113 62 9 142 84 142
134 0 26 -4 31 -25 31 -14 0 -41 -15 -64 -35 -37 -34 -69 -43 -105 -29 -9 3
-16 10 -16 15 0 5 10 4 23 -2 19 -8 31 -6 60 11 41 24 44 32 22 50 -8 7 -15
16 -15 21 0 16 125 109 168 126 l43 16 167 -164 c92 -91 220 -215 285 -275 64
-61 117 -113 117 -115 0 -2 -43 -4 -95 -4 -52 -1 -98 -4 -102 -8 -16 -17 25
-26 97 -21 41 3 93 2 115 -3 l40 -10 -2 119 c-2 66 -6 124 -9 130 -14 22 -24
-20 -24 -106 l0 -88 -167 173 c-91 96 -215 223 -275 283 -60 59 -108 109 -106
110 88 40 235 119 290 155 114 74 196 189 199 277 1 22 6 60 11 87 8 36 6 65
-7 121 -16 73 -91 244 -122 279 -20 22 -11 32 15 18 12 -6 36 -11 54 -11 37 0
138 43 138 59 0 19 -20 22 -49 6 -32 -16 -171 -22 -171 -6 0 5 39 11 88 13 60
3 102 11 135 26 43 19 48 25 45 49 -4 34 -37 41 -116 23 -30 -6 -55 -10 -57
-8 -2 1 31 102 72 223 103 305 97 277 74 330 -11 25 -24 45 -29 45 -21 0 -65
-104 -70 -166 -2 -36 -8 -66 -11 -68 -3 -2 -7 48 -9 112 -2 65 -7 164 -11 223
-16 199 -69 256 -336 362 -49 20 -102 39 -117 42 -41 10 -35 29 7 22 39 -6 40
-5 24 25 -10 19 10 42 215 247 124 124 226 231 226 236 0 5 -102 -91 -226
-215 -203 -203 -227 -223 -237 -206 -64 101 -111 146 -154 146 -58 0 -108 -45
-118 -107 -12 -76 53 -173 117 -173 9 0 20 -5 24 -12 11 -18 -26 -38 -70 -38
-23 0 -79 -14 -126 -31 -232 -82 -264 -91 -315 -86 -27 2 -58 8 -67 12 -15 6
-18 2 -18 -19 0 -34 29 -62 79 -77 23 -7 41 -15 41 -18 0 -3 -29 -24 -63 -46
-34 -22 -62 -47 -62 -55 0 -18 63 -38 125 -40 40 -1 42 -3 23 -12 -17 -8 -23
-20 -23 -43 0 -20 -8 -39 -21 -51 -20 -18 -21 -17 -82 50 -42 45 -75 72 -102
81 -22 8 -32 14 -22 15 23 0 22 13 -4 36 -12 10 -30 37 -41 58 -18 35 -20 51
-14 124 6 67 5 83 -6 80 -15 -5 -35 -42 -43 -80 -8 -41 -25 -34 -18 7 5 31 3
35 -22 41 -16 4 -35 2 -45 -3 -13 -9 -19 -5 -29 19 -28 63 -70 37 -71 -44 0
-34 -4 -48 -14 -48 -9 0 -12 7 -9 20 7 26 -23 60 -52 60 -32 0 -49 -24 -41
-60 3 -17 1 -30 -4 -30 -13 0 -30 34 -30 60 0 22 -15 31 -55 34 -30 1 -32 17
-5 41 16 14 24 15 51 6 47 -16 91 -14 152 10 51 19 56 20 85 5 17 -9 46 -16
65 -16 18 0 38 -5 44 -11 8 -8 15 -4 27 12 12 18 14 38 10 89 -4 47 -1 78 11
110 32 90 39 101 68 107 16 3 31 12 34 19 7 19 22 18 68 -6 46 -23 54 -20 31
15 -10 16 -33 30 -61 37 -53 14 -59 28 -12 28 45 0 127 -41 169 -84 19 -20 38
-36 42 -36 17 0 2 36 -29 67 -20 20 -34 38 -31 40 13 14 80 -24 116 -64 22
-25 43 -44 45 -41 8 8 -11 52 -29 67 -9 7 -16 17 -16 22 0 10 89 -16 103 -30
15 -15 27 -14 27 3 0 8 -22 27 -49 43 l-50 27 40 43 c21 23 39 48 39 55 0 7
-10 1 -23 -13 -42 -50 -68 -60 -127 -49 -29 5 -54 10 -56 10 -2 0 -4 4 -4 9 0
5 38 26 85 47 47 21 85 42 85 47 0 17 -73 -4 -152 -44 -42 -21 -81 -39 -85
-39 -22 0 49 53 103 77 65 28 80 43 44 43 -33 0 -85 -25 -155 -75 -65 -47
-141 -75 -202 -75 -18 0 -33 4 -33 9 0 15 60 57 117 81 94 40 95 73 2 41 -30
-10 -63 -22 -73 -26 -21 -9 -19 -4 16 48 17 24 25 27 79 27 61 0 83 13 28 17
-44 3 -46 9 -16 34 46 35 34 50 -18 22z m-207 -205 c-3 -7 -13 -15 -24 -17
-16 -3 -17 -1 -5 13 16 19 34 21 29 4z m-33 -68 c-3 -5 -15 -10 -26 -10 -11 0
-17 5 -14 10 3 6 15 10 26 10 11 0 17 -4 14 -10z m52 -82 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m133 3 c0 -16 -18 -22 -45 -15 -37 9 -31
24 10 24 19 0 35 -4 35 -9z m-85 -39 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10
-3 15 -9 12 -12z m-915 -27 c-7 -8 -17 -15 -22 -15 -6 0 -5 7 2 15 7 8 17 15
22 15 6 0 5 -7 -2 -15z m767 -49 c-27 -36 -61 -90 -75 -118 -2 -3 5 -8 15 -11
14 -4 13 -5 -4 -6 -13 0 -23 5 -23 12 0 30 95 165 117 167 2 0 -12 -20 -30
-44z m-743 -18 c-45 -67 -44 -66 -44 -39 0 20 58 91 74 91 4 0 -10 -24 -30
-52z m1702 -30 c16 -17 22 -27 14 -22 -29 17 -100 67 -100 70 0 12 63 -23 86
-48z m-1369 -13 c-15 -16 -22 -11 -13 10 3 9 10 13 16 10 6 -4 5 -11 -3 -20z
m1283 -5 c0 -5 24 -24 53 -41 42 -25 46 -29 22 -24 -16 3 -40 13 -52 21 -26
17 -49 54 -33 54 5 0 10 -4 10 -10z m-1959 -110 c13 -60 -31 -184 -79 -221
-15 -13 -15 -12 1 11 51 72 72 166 52 230 -5 16 -3 21 6 18 8 -3 17 -19 20
-38z m-71 -61 c0 -38 -26 -106 -37 -96 -2 3 0 14 6 25 6 12 11 44 11 72 0 32
4 49 10 45 6 -3 10 -24 10 -46z m2245 20 c22 -6 51 -19 65 -30 l25 -21 -25 6
c-14 3 -56 11 -95 17 -118 18 -118 18 -105 34 13 16 56 14 135 -6z m-1736 -70
c-8 -5 -21 -9 -29 -9 -12 1 -11 4 4 15 10 8 23 12 29 8 5 -3 3 -9 -4 -14z
m631 -28 c0 -17 -4 -33 -10 -36 -6 -4 -10 8 -10 29 0 20 5 36 10 36 6 0 10
-13 10 -29z m987 23 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-897 -35 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m1254 -55
c59 -65 85 -100 86 -118 0 -15 -30 15 -89 89 -64 78 -75 95 -65 95 4 0 34 -30
68 -66z m-2685 30 c16 -21 21 -41 21 -90 0 -34 -4 -66 -10 -69 -6 -4 -10 16
-10 52 0 39 -7 71 -20 96 -23 43 -11 49 19 11z m1222 13 c-1 -12 -15 -9 -19 4
-3 6 1 10 8 8 6 -3 11 -8 11 -12z m153 -12 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10
15 0 8 2 15 4 15 2 0 6 -7 10 -15z m946 5 c-20 -13 -33 -13 -25 0 3 6 14 10
23 10 15 0 15 -2 2 -10z m245 -40 c22 -15 49 -38 60 -51 19 -22 18 -22 -15 3
-19 13 -56 37 -82 52 -27 15 -48 29 -48 32 0 10 46 -9 85 -36z m-1193 -57 c27
-24 22 -36 -7 -17 -14 9 -25 20 -25 25 0 14 11 11 32 -8z m78 -4 c0 -6 -4 -7
-10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m983 5 c26 -9 47
-20 47 -25 0 -5 9 -9 19 -9 24 0 132 -91 166 -140 45 -65 87 -238 65 -270 -7
-11 -10 -5 -10 25 0 73 -59 118 -75 58 -6 -27 -7 -26 -32 25 -29 62 -70 92
-124 92 -57 0 -64 -18 -34 -85 l25 -57 -40 36 c-48 43 -74 37 -83 -16 -4 -27
4 -49 44 -116 49 -83 72 -151 62 -181 -15 -39 -65 -99 -95 -114 -18 -9 -27
-17 -20 -17 24 0 11 -16 -47 -53 -60 -39 -137 -70 -203 -82 -31 -6 -38 -4 -38
9 0 15 -96 49 -205 73 -38 8 -39 10 -50 68 -6 33 -17 71 -24 83 -25 46 0 34
61 -30 48 -50 75 -69 114 -82 28 -9 62 -28 74 -41 27 -29 86 -36 76 -9 -3 9
-6 22 -6 29 0 24 -28 43 -97 65 -38 13 -72 27 -78 33 -7 7 -4 8 10 3 11 -4 52
-19 90 -33 39 -14 81 -35 95 -48 46 -41 66 -47 122 -35 73 15 84 27 57 56 -23
25 -22 26 32 38 36 8 79 44 79 67 0 12 -9 21 -25 25 -24 6 -25 8 -10 23 8 10
15 23 15 29 0 15 -49 16 -85 2 -15 -6 -61 -11 -103 -12 -95 -3 -154 22 -254
106 -83 69 -174 192 -157 213 8 8 16 10 23 4 12 -10 156 91 188 132 10 12 18
17 18 11 0 -15 -21 -38 -60 -64 -19 -13 -37 -25 -39 -26 -6 -5 -30 -129 -25
-133 2 -2 16 2 31 10 16 7 32 15 36 17 5 2 2 -10 -7 -26 -20 -39 -20 -65 -1
-102 25 -48 62 -70 118 -70 64 0 112 28 127 76 12 35 9 123 -5 174 -7 23 -6
23 14 -5 11 -16 23 -42 26 -57 14 -64 45 -41 45 34 0 40 -4 50 -32 73 -38 31
-78 92 -78 118 0 31 17 18 29 -22 21 -71 69 -105 107 -76 18 14 18 16 -15 46
l-35 32 38 -7 c45 -9 73 -1 86 25 9 16 7 21 -12 28 -113 44 -135 61 -79 61 47
0 81 17 81 40 0 24 4 24 63 4z m-2004 -7 c8 -11 2 -17 -27 -31 -90 -43 -193
-131 -246 -211 -14 -22 -26 -35 -26 -29 0 11 64 111 97 151 22 28 164 133 179
133 6 0 17 -6 23 -13z m881 -27 c17 -22 29 -40 26 -40 -8 0 -106 71 -106 76 0
2 11 4 24 4 16 0 35 -13 56 -40z m-120 -5 c37 -38 36 -60 -1 -23 -13 13 -35
31 -49 40 -23 16 -24 17 -4 17 11 1 35 -15 54 -34z m1489 -29 c-2 -2 -13 8
-23 22 l-19 27 24 -22 c12 -12 21 -24 18 -27z m-605 -182 c-18 -59 -24 -94
-20 -118 7 -39 0 -48 -33 -39 -18 4 -21 12 -19 36 2 17 13 41 25 55 26 28 53
103 53 146 0 17 4 49 8 71 l7 40 3 -54 c2 -37 -5 -79 -24 -137z m-554 156 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-167
-7 c-18 -2 -48 -2 -65 0 -18 2 -4 4 32 4 36 0 50 -2 33 -4z m-938 -17 c-22 -7
-55 -21 -74 -30 -18 -10 -30 -12 -26 -6 11 18 86 50 115 49 19 0 16 -3 -15
-13z m1018 7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m69 -15
c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z m-897 -33 l30 -15
-40 6 c-22 3 -47 10 -55 15 -26 15 32 10 65 -6z m805 -18 c45 -18 57 -28 69
-60 7 -20 12 -39 10 -42 -5 -5 -223 -24 -227 -20 -2 2 2 21 8 44 7 27 16 41
27 41 13 0 14 -3 5 -12 -19 -19 -14 -28 13 -28 26 0 35 24 13 33 -7 3 10 6 37
6 92 2 75 17 -21 18 -94 1 -95 1 -105 -25 -6 -15 -11 -39 -11 -54 -1 -24 -6
-29 -37 -34 -20 -3 -43 -8 -52 -10 -24 -7 -42 21 -34 53 10 41 32 71 68 93 46
29 163 27 237 -3z m162 -4 c10 -9 18 -20 18 -25 0 -11 -38 14 -44 29 -7 18 4
16 26 -4z m-1644 -92 c19 -34 32 -65 29 -67 -3 -3 -20 22 -38 56 -18 34 -41
66 -51 71 -10 5 -18 15 -18 21 0 26 47 -23 78 -81z m510 77 c7 -7 12 -21 12
-31 0 -17 -2 -17 -20 6 -11 14 -20 28 -20 31 0 10 15 7 28 -6z m-206 -35 c-11
-16 -29 -46 -41 -68 -11 -22 -21 -33 -21 -25 0 18 62 120 73 120 5 0 0 -12
-11 -27z m797 -40 c-36 -38 -75 -85 -88 -106 -13 -21 -26 -36 -28 -33 -10 10
58 104 112 155 77 73 80 63 4 -16z m-306 -31 c91 -90 90 -85 26 -182 -52 -76
-106 -110 -191 -117 -35 -3 -66 -10 -69 -15 -3 -5 -16 0 -30 10 -17 13 -40 18
-76 17 -62 -2 -68 -6 -53 -37 11 -21 11 -22 -3 -8 -10 10 -18 42 -23 90 -5 65
-3 84 19 148 45 128 0 141 -71 21 -28 -47 -29 -121 -2 -174 24 -47 25 -51 4
-34 -26 22 -54 86 -54 127 0 98 69 202 133 202 36 0 60 -20 117 -102 32 -47
67 -67 138 -83 42 -9 40 3 -8 50 -22 21 -38 40 -36 42 6 7 140 -47 158 -64 17
-15 18 -15 18 6 0 31 -25 78 -72 134 -65 78 -16 58 75 -31z m946 1 c18 -24 29
-43 24 -43 -10 0 -43 42 -63 80 -15 29 -5 20 39 -37z m-1054 -3 c4 -6 1 -10
-6 -10 -15 0 -59 39 -59 52 0 10 55 -26 65 -42z m-701 -32 c70 -80 55 -82 -19
-3 -36 38 -65 71 -65 73 0 12 46 -27 84 -70z m213 5 c-3 -10 -5 -4 -5 12 0 17
2 24 5 18 2 -7 2 -21 0 -30z m-271 -39 c46 -19 108 -64 100 -72 -3 -3 -14 2
-23 11 -10 8 -52 29 -93 46 -52 21 -67 30 -49 30 15 1 44 -6 65 -15z m1599
-48 c-6 -27 -2 -39 14 -55 31 -31 50 -25 74 21 l14 27 17 -54 c11 -35 23 -56
36 -60 25 -8 26 -25 1 -25 -42 0 -106 -33 -167 -86 -35 -31 -66 -55 -67 -53
-9 12 -37 293 -30 299 11 11 41 17 81 19 32 1 33 0 27 -33z m-222 -15 c16 -6
34 -29 51 -65 22 -46 26 -69 26 -145 0 -86 -1 -91 -23 -96 -55 -14 -136 47
-221 166 -36 51 -66 96 -66 100 0 9 60 27 130 39 65 11 76 11 103 1z m791 -31
c10 -13 9 -13 -6 -1 -10 7 -18 21 -17 30 0 13 1 14 6 1 3 -8 11 -22 17 -30z
m-351 10 c3 -11 1 -18 -4 -14 -5 3 -9 12 -9 20 0 20 7 17 13 -6z m-1533 -45
c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m752
19 c14 -13 -158 -154 -188 -154 -25 0 -15 16 21 33 43 21 125 95 125 113 0 14
31 20 42 8z m106 -37 l23 -42 -23 25 c-13 14 -32 33 -43 42 l-20 18 20 0 c13
0 28 -14 43 -43z m803 -109 c50 -48 64 -64 34 -40 -65 51 -155 147 -155 164 0
7 9 0 19 -15 10 -16 56 -65 102 -109z m-1866 57 c50 -77 153 -157 248 -190 71
-25 98 -45 63 -45 -22 0 -28 -17 -16 -41 10 -18 10 -20 -8 -10 -11 6 -22 8
-25 5 -3 -3 -13 7 -23 23 -28 44 -71 86 -82 79 -5 -3 -13 -26 -16 -51 -8 -47
-14 -53 -34 -33 -19 19 -14 39 15 70 15 15 22 28 17 28 -15 0 -55 -47 -101
-117 -38 -58 -69 -161 -58 -196 2 -7 6 -25 10 -42 4 -16 25 -46 47 -67 33 -30
39 -42 34 -62 -16 -66 -96 -58 -168 17 -76 79 -83 97 -81 207 2 110 33 216 81
278 39 50 86 132 79 138 -3 3 -16 -11 -30 -33 -27 -43 -82 -99 -112 -115 -11
-6 1 11 27 38 89 91 102 127 29 73 -24 -17 -45 -29 -47 -26 -8 8 69 61 90 62
17 2 21 8 20 34 -1 17 0 31 2 31 2 0 20 -25 39 -55z m1732 19 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1178 -80 c-14 -43 -30 -86 -36 -94
-9 -13 -10 -13 -5 0 2 8 17 52 32 98 14 45 28 81 31 79 2 -2 -8 -40 -22 -83z
m-2485 -46 c0 -10 -136 58 -145 72 -4 6 28 -6 69 -27 42 -21 76 -41 76 -45z
m688 50 c17 -17 15 -29 -3 -22 -42 16 -143 -67 -203 -167 -20 -33 -30 -39 -95
-54 -118 -29 -227 -65 -227 -76 0 -6 13 -24 29 -40 52 -52 91 -186 91 -314 0
-98 -11 -121 -112 -222 -51 -51 -96 -93 -101 -93 -5 0 -16 9 -25 19 -23 27
-117 72 -232 112 -116 41 -95 50 34 15 106 -29 276 -46 276 -28 0 21 -55 40
-130 47 -116 11 -116 24 0 18 111 -5 185 10 185 38 0 17 -85 31 -134 22 -23
-4 -32 -2 -27 5 7 12 53 27 109 37 43 7 48 20 11 29 -36 9 -78 -1 -149 -37
-64 -32 -166 -70 -172 -63 -2 2 23 13 55 25 76 29 170 122 211 207 17 35 44
73 67 92 38 31 38 31 13 32 -14 0 -41 -10 -60 -23 l-34 -23 0 70 c0 80 -11
130 -26 121 -5 -4 -21 -25 -35 -47 -14 -22 -27 -38 -29 -36 -2 2 1 23 7 47 9
35 8 49 -6 78 -20 43 -15 51 21 32 19 -10 65 -14 172 -14 142 0 148 1 226 33
58 24 95 47 135 86 64 61 121 106 136 106 5 0 15 -5 22 -12z m-388 -18 c0 -5
-10 -10 -22 -10 -19 0 -20 2 -8 10 19 13 30 13 30 0z m230 1 c0 -5 -7 -12 -16
-15 -14 -5 -15 -4 -4 9 14 17 20 19 20 6z m1290 -22 c18 -10 -57 1 -85 12 -12
5 -2 6 25 2 25 -3 52 -9 60 -14z m475 -43 c-10 -14 -21 -26 -26 -26 -5 0 0 13
12 30 27 36 38 33 14 -4z m-969 -18 c15 -24 39 -55 53 -70 14 -16 23 -28 20
-28 -2 0 -53 7 -114 15 -60 8 -118 15 -128 15 -16 0 -16 2 -3 29 24 45 73 81
110 81 28 0 38 -7 62 -42z m61 0 c45 -50 55 -101 12 -62 -19 17 -49 65 -49 79
0 12 18 4 37 -17z m-1342 2 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10
4 10 5 0 13 -4 16 -10z m286 -31 c-24 -11 -46 -17 -49 -14 -3 2 6 8 19 13 13
5 31 12 39 15 37 14 31 4 -9 -14z m2095 -17 c-8 -16 -22 -35 -32 -43 -10 -8
-5 4 11 29 32 48 42 55 21 14z m-1665 -2 c42 -19 43 -20 14 -20 -16 0 -41 9
-55 20 -32 25 -14 25 41 0z m-581 0 c13 -9 13 -10 0 -9 -8 0 -24 4 -35 9 l-20
9 20 0 c11 0 27 -4 35 -9z m-370 -113 c19 -60 5 -53 -19 9 -11 31 -21 59 -21
62 0 15 28 -36 40 -71z m-141 -98 c1 -3 17 -26 35 -50 19 -24 33 -45 31 -47
-1 -2 -21 6 -44 17 -65 34 -132 24 -100 -15 6 -8 9 -18 5 -21 -14 -14 -26 23
-25 77 0 58 15 113 44 157 l18 27 18 -70 c10 -38 18 -72 18 -75z m1261 106
c-13 -14 -26 -23 -28 -20 -3 3 5 16 18 30 13 14 26 23 28 20 3 -3 -5 -16 -18
-30z m-1225 -6 c9 -17 14 -36 12 -42 -4 -12 -37 44 -37 63 0 18 9 11 25 -21z
m1155 16 c0 -23 -35 -37 -64 -25 -46 17 -31 40 26 40 27 0 38 -4 38 -15z m465
-5 c71 -11 164 -30 206 -41 l77 -21 -29 -61 c-63 -136 -77 -296 -39 -438 77
-285 77 -432 -2 -613 -40 -91 -48 -99 -48 -51 0 22 -7 67 -15 100 -11 45 -14
130 -15 337 0 177 -4 279 -10 283 -6 4 -2 14 11 24 27 22 40 118 18 131 -15
10 -39 -6 -39 -24 0 -7 -5 -17 -12 -24 -17 -17 -38 4 -38 40 0 27 -2 29 -32
26 -28 -3 -33 -7 -36 -33 -5 -43 -20 -53 -28 -19 -9 35 -18 42 -50 36 -22 -4
-24 -9 -24 -59 0 -31 6 -63 15 -77 13 -20 16 -71 18 -303 1 -199 -1 -284 -9
-294 -7 -8 -15 -53 -19 -101 -4 -49 -12 -88 -17 -88 -5 0 -22 7 -38 15 -25 13
-30 21 -30 54 0 22 -9 90 -19 153 -20 117 -38 448 -32 577 2 44 11 91 22 115
12 27 19 64 19 109 l0 67 -39 0 c-41 0 -51 -13 -51 -67 0 -16 -6 -23 -20 -23
-17 0 -20 7 -20 45 l0 45 -39 0 c-41 0 -51 -13 -51 -67 0 -13 -6 -23 -14 -23
-10 0 -16 14 -18 43 -3 39 -5 42 -33 42 l-30 0 -3 -81 c-2 -68 0 -85 15 -100
22 -22 31 -123 32 -379 1 -137 -3 -195 -15 -240 -9 -33 -17 -103 -18 -155 -1
-89 -2 -95 -23 -98 -19 -3 -22 3 -28 55 -4 32 -14 87 -22 123 -22 92 -30 443
-13 519 8 31 20 65 27 76 7 11 13 40 12 65 -1 109 -53 160 -86 83 l-16 -38
-10 35 c-5 19 -11 41 -13 48 -2 7 -21 12 -49 12 -45 0 -45 0 -45 -34 0 -19 -5
-38 -11 -42 -14 -8 -29 14 -29 44 0 15 -6 22 -19 22 -47 0 -51 -8 -51 -94 0
-92 16 -129 53 -122 12 1 17 1 10 -1 -8 -3 -13 -82 -17 -271 -4 -147 -9 -315
-12 -374 -5 -100 -7 -108 -25 -108 -11 0 -30 -5 -42 -11 -19 -11 -25 -7 -54
26 -88 102 -104 274 -54 576 17 102 31 212 31 245 0 78 -23 182 -55 252 -20
43 -23 57 -13 63 12 8 293 79 310 79 4 0 21 -26 37 -59 17 -32 37 -61 46 -65
9 -3 62 -6 117 -6 100 0 100 0 127 32 14 17 44 49 65 71 38 38 41 39 92 34 40
-4 54 -2 58 9 7 19 46 18 201 -6z m-535 -60 c21 0 51 5 67 10 27 10 27 9 8 -5
-14 -11 -37 -15 -74 -13 -51 3 -56 5 -74 38 l-19 35 27 -32 c21 -27 34 -33 65
-33z m-943 8 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m2445 -68
c45 -37 98 -140 98 -189 0 -41 -22 -93 -72 -171 -39 -61 -52 -102 -43 -131 10
-30 24 -23 47 26 12 25 26 45 30 45 12 0 10 -5 -14 -52 -30 -56 -30 -120 -2
-148 l21 -21 35 39 c34 38 36 44 37 113 1 41 6 106 11 144 8 60 8 52 5 -57 -2
-71 0 -128 4 -128 5 0 14 7 21 15 20 25 23 10 5 -27 -33 -65 -105 -127 -170
-147 -74 -23 -272 -40 -261 -22 4 6 14 11 22 11 8 0 36 21 62 48 39 38 53 62
74 127 42 130 43 149 9 256 l-31 95 20 39 20 39 0 -31 c0 -37 31 -79 52 -70
21 7 30 64 18 106 -5 19 -23 52 -40 72 -27 34 -27 44 0 48 3 1 22 -13 42 -29z
m-93 0 c-8 -5 -24 -28 -34 -52 -11 -24 -20 -45 -22 -47 -3 -5 -54 56 -54 66 0
9 87 42 111 42 13 0 13 -1 -1 -9z m-2156 -48 c-3 -20 -10 -44 -15 -52 -7 -11
-8 -8 -4 10 3 14 6 40 6 58 0 18 4 31 9 27 5 -3 7 -22 4 -43z m2186 -5 c0 -27
-2 -29 -9 -12 -12 28 -12 57 0 50 6 -3 10 -21 9 -38z m-175 -29 c58 -38 77
-67 113 -173 32 -93 35 -189 9 -278 -32 -111 -118 -168 -265 -175 -77 -4 -89
-2 -113 18 -29 22 -37 50 -16 50 19 0 68 52 68 72 0 19 -33 58 -50 58 -6 0
-22 -12 -37 -27 -21 -23 -25 -25 -22 -9 2 11 19 29 37 39 33 19 33 19 56 -2
28 -26 42 -27 58 -2 6 10 18 17 25 14 18 -7 63 35 63 59 0 10 -11 30 -25 43
-55 56 -151 18 -206 -81 -32 -56 -47 -67 -30 -21 15 38 79 106 122 128 54 27
81 24 115 -12 32 -35 81 -59 116 -59 38 0 52 51 31 111 -17 47 -17 48 5 72 19
21 21 28 12 49 -15 32 -55 55 -118 68 -71 16 -120 1 -150 -46 -32 -49 -39 -36
-11 20 12 25 20 48 18 51 -3 2 -22 0 -42 -6 -41 -11 -91 -65 -108 -118 l-12
-34 -30 64 -30 64 -8 -45 c-5 -25 -15 -81 -22 -125 -19 -107 -30 -77 -25 63 3
69 10 120 21 145 l16 37 118 1 c65 1 132 5 148 9 70 18 120 11 169 -22z
m-2099 -44 c-22 -53 -27 -38 -7 19 9 27 18 42 20 34 2 -8 -4 -32 -13 -53z
m-138 -96 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m2136 -15
c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m-964 -7 c-9 -8 -98
-13 -93 -5 3 5 26 9 51 9 26 0 44 -2 42 -4z m84 -3 c-7 -2 -21 -2 -30 0 -10 3
-4 5 12 5 17 0 24 -2 18 -5z m672 -83 c-21 -22 -41 -37 -44 -34 -5 5 68 73 79
74 2 0 -13 -18 -35 -40z m275 -25 c16 -19 1 -19 -24 0 -16 12 -17 15 -4 15 8
0 21 -7 28 -15z m-1392 -2 c-16 -2 -40 -2 -55 0 -16 2 -3 4 27 4 30 0 43 -2
28 -4z m1432 -21 c0 -15 -2 -15 -10 -2 -13 20 -13 33 0 25 6 -3 10 -14 10 -23z
m-2357 1 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m1695 -10
c-21 -2 -57 -2 -80 0 -24 2 -7 4 37 4 44 0 63 -2 43 -4z m974 -15 c-7 -7 -12
-8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-2614 -15 c-10 -2 -26 -2
-35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m2126 -29 c23 -23 20 -40 -4 -19 -13
11 -29 16 -47 12 -26 -5 -26 -4 -8 9 24 18 40 18 59 -2z m-896 -114 c4 -59 2
-70 -12 -70 -13 0 -16 13 -16 82 0 64 3 79 12 70 7 -7 14 -44 16 -82z m376
-32 c18 -68 -8 -166 -34 -128 -8 13 -5 103 6 138 6 19 21 14 28 -10z m-734
-84 c0 -48 -3 -65 -12 -62 -8 3 -14 28 -16 66 -2 51 0 62 13 62 12 0 15 -13
15 -66z m1705 -59 c-44 -36 -198 -119 -326 -176 -62 -27 -119 -55 -126 -61 -8
-6 -17 -8 -21 -3 -4 4 18 30 48 58 51 48 66 55 180 90 69 21 141 48 160 60 92
57 136 73 85 32z m-2501 -21 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11
14 -7z m136 -49 c-7 -9 -23 -21 -34 -27 -16 -8 -14 -3 8 21 29 31 51 36 26 6z
m1057 -33 c6 -4 -10 -7 -35 -7 -26 0 -63 -3 -82 -8 -21 -5 -31 -4 -25 2 17 16
122 26 142 13z m-419 -39 c-15 -2 -42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33
-4z m802 -53 c10 -7 -6 -10 -55 -10 -49 0 -65 3 -55 10 8 5 33 10 55 10 22 0
47 -5 55 -10z m383 -52 c-8 -44 -22 -70 -34 -63 -15 10 -11 44 9 70 24 34 33
31 25 -7z m-803 -33 c7 -9 13 -32 14 -53 1 -36 0 -37 -34 -37 -33 0 -35 2 -42
44 -4 24 -5 48 -2 52 9 14 51 10 64 -6z m-336 -42 c7 -53 -1 -66 -35 -61 -20
2 -25 10 -27 42 -4 51 3 66 31 66 21 0 25 -6 31 -47z m1091 -62 c19 -10 19
-10 -2 -11 -27 0 -63 33 -63 58 0 14 5 12 23 -9 12 -15 31 -32 42 -38z m-377
-14 c3 -25 0 -28 -20 -25 -17 2 -24 11 -26 31 -3 25 0 28 20 25 17 -2 24 -11
26 -31z m494 -29 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z"
            />
            <path
                d="M2664 3149 c-3 -6 -1 -16 4 -21 13 -13 45 8 36 23 -7 12 -31 11 -40
    -2z"
            />
            <path d="M2730 3146 c0 -8 4 -17 9 -20 11 -7 33 18 24 27 -12 12 -33 7 -33 -7z" />
            <path
                d="M2666 2951 c-10 -16 5 -42 23 -39 21 4 21 42 -1 46 -9 2 -19 -1 -22
    -7z"
            />
            <path d="M2680 2851 c0 -15 6 -21 21 -21 14 0 19 5 17 17 -5 26 -38 29 -38 4z" />
            <path
                d="M2692 2768 c2 -13 10 -23 18 -23 8 0 16 10 18 23 3 17 -2 22 -18 22
    -16 0 -21 -5 -18 -22z"
            />
            <path
                d="M3989 4233 c-16 -19 6 -25 104 -28 51 -1 94 -3 97 -4 3 0 3 -29 2
    -62 -5 -81 6 -155 20 -141 6 6 11 61 12 129 l2 118 -114 0 c-74 0 -117 -4
-123 -12z"
            />
            <path
                d="M907 4089 c1 -67 6 -126 12 -132 14 -14 20 20 23 123 l3 85 85 3
c109 3 135 8 121 26 -8 10 -24 11 -63 5 -29 -5 -69 -6 -88 -3 -19 3 -48 7 -65
9 l-30 5 2 -121z"
            />
            <path
                d="M828 1074 c-3 -3 -3 -43 -1 -88 7 -109 1 -133 -40 -150 -28 -12 -34
    -12 -44 2 -11 13 -14 11 -23 -12 -5 -15 -10 -33 -10 -41 0 -7 -9 -23 -20 -36
l-20 -24 16 31 c35 62 -16 126 -71 90 -14 -9 -25 -24 -25 -32 0 -13 3 -14 14
-5 16 13 42 6 50 -15 8 -20 -3 -44 -20 -44 -8 0 -18 -8 -21 -17 -13 -37 -54
-43 -108 -17 -58 27 -68 95 -17 123 15 8 13 11 -13 21 -92 35 -177 -69 -130
-160 23 -45 67 -63 141 -58 l64 4 -68 -31 c-37 -18 -79 -46 -94 -63 -23 -28
-85 -198 -76 -208 8 -8 164 48 201 71 29 20 47 44 71 98 l33 71 -5 -64 c-6
-77 14 -121 63 -144 41 -20 59 -20 100 0 43 20 69 73 60 120 -7 40 -11 41 -34
13 -20 -25 -63 -28 -88 -8 -41 35 -54 139 -16 139 8 0 17 9 20 20 3 10 14 22
25 25 27 9 51 -13 43 -42 -7 -28 6 -30 28 -5 37 41 4 98 -52 90 -45 -7 -27 9
19 17 38 6 57 25 25 25 -14 0 -14 3 0 29 13 27 21 30 81 36 36 4 90 5 119 1
39 -4 56 -2 64 8 10 12 3 16 -44 21 -31 4 -80 5 -110 3 l-55 -5 0 93 c0 109
-9 142 -32 118z"
            />
            <path
                d="M4317 1034 c-4 -4 -7 -53 -7 -109 l0 -102 -55 5 c-30 2 -79 1 -110
    -3 -45 -5 -54 -9 -45 -20 8 -10 31 -12 88 -8 42 3 95 1 117 -4 l40 -10 -2 120
c-3 113 -9 147 -26 131z"
            />
            <path
                d="M4647 784 c-3 -4 3 -18 13 -32 11 -13 20 -30 20 -37 0 -21 -46 -56
    -81 -61 -28 -5 -40 0 -69 26 -28 25 -32 34 -23 48 6 9 20 16 32 15 23 -3 27 7
9 25 -22 22 -67 14 -80 -14 l-12 -26 -8 23 c-6 16 -12 19 -19 12 -8 -8 -19 -7
-37 3 -34 17 -37 13 -17 -25 9 -17 11 -31 5 -31 -19 0 -10 -18 13 -24 l22 -6
-24 -13 c-39 -20 -48 -43 -27 -66 24 -26 36 -27 29 -3 -2 10 2 24 10 30 17 15
47 6 47 -14 0 -7 9 -18 20 -24 28 -15 26 -62 -6 -99 -30 -36 -56 -39 -85 -10
-23 23 -26 23 -29 -6 -8 -74 25 -115 93 -115 35 0 49 6 72 30 26 27 29 38 29
88 l-1 57 22 -51 c12 -27 36 -61 53 -76 28 -23 168 -75 178 -65 6 6 -26 108
-47 149 -20 40 -61 73 -102 83 -39 10 -47 29 -8 20 111 -24 188 90 112 166
-21 21 -39 29 -64 29 -18 0 -37 -3 -40 -6z m-173 -80 c3 -8 2 -12 -4 -9 -6 3
-10 10 -10 16 0 14 7 11 14 -7z"
            />
        </g>
    </svg>
);

CocktailIcon.propTypes = {
    size: PropTypes.number,
};

export default CocktailIcon;
