import React from 'react';
import styled from '@emotion/styled';
import { getYear } from 'date-fns';
import Link from '../Link';
import leafImage from '../../images/footer-leaf.png';

const FooterStyled = styled.footer``;

const ImageWrapper = styled.div`
    text-align: center;
`;

const FooterContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
`;

const FooterLine = styled.div`
    font-size: 18px;
    margin: 8px 0;
    text-align: center;
`;

const Footer = () => {
    const year = getYear(new Date());

    return (
        <FooterStyled>
            <ImageWrapper>
                <img src={leafImage} alt="Leaf" width="64" />
            </ImageWrapper>
            <FooterContent>
                <FooterLine>
                    Credits:{' '}
                    <Link href="https://github.com/murgatt/" target="_blank">
                        @murgatt
                    </Link>
                </FooterLine>
                <FooterLine>
                    Special thanks to:{' '}
                    <Link href="https://studiochillimango.com/" target="_blank">
                        Studio Chilli Mango
                    </Link>
                </FooterLine>
                <FooterLine>Copyright &copy; {year}, Théo Murgat</FooterLine>
            </FooterContent>
        </FooterStyled>
    );
};

export default Footer;
