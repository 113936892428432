import WeddingIcon from '../components/eventSection/WeddingIcon';
import CocktailIcon from '../components/eventSection/CocktailIcon';

import castelFleuri from '../images/places/castel-fleuri.jpg';
import hotelDuRoyans from '../images/places/hotel-du-royans.jpg';
import masDeServant from '../images/places/mas-de-servant.jpg';
import museeEau from '../images/places/musee-eau.jpg';
import hotelColMachine from '../images/places/hotel-col-machine.jpg';
import campingManne from '../images/places/camping-manne.jpg';

export default {
    date: '2021-08-05T15:00:00',
    initials: ['J', 'T'],
    names: ['Joséphine', 'Théo'],
    listUrl: 'https://www.millemercismariage.com/josephine-theo/liste.html',
    events: [
        {
            title: 'Cérémonie',
            time: '15h',
            icon: WeddingIcon,
            text: ['Collégiale Saint-Pierre & Saint-Paul', "Saint-Donat-sur-l'Herbasse"],
            locationLink: 'https://goo.gl/maps/vGvbiy5sjewY47kX7',
        },
        {
            title: 'Cocktail',
            time: '18h',
            icon: CocktailIcon,
            text: ['Domaine de la Chartrognière', 'Saint-Thomas-en-Royans'],
            locationLink: 'https://goo.gl/maps/yxQDMfcRXQ8KzWHPA',
        },
    ],
    places: [
        {
            title: 'Le Castel Fleuri',
            address: '16 Place du Champs de Mars, 26190 Saint-Jean-en-Royans',
            image: castelFleuri,
            url: 'https://www.hotelcastelfleurivercors.com/',
        },
        {
            title: 'Le Mas de Servant',
            address: '940 Route du Vercors, 38680 Auberives-en-Royans',
            image: masDeServant,
            url: 'https://www.le-mas-de-servant.fr/fr/',
        },
        {
            title: "Hôtel du Musée de l'eau",
            address: 'Place du Breuil, 38680 Pont-en-Royans',
            image: museeEau,
            url:
                'https://www.tripadvisor.fr/Hotel_Review-g1572484-d1591493-Reviews-Hotel_Du_Musee_De_l_Eau-Pont_en_Royans_Isere_Auvergne_Rhone_Alpes.html',
        },
        {
            title: 'Hôtel du Royans',
            address: 'Grande Rue, 38680 Pont-en-Royans',
            image: hotelDuRoyans,
            url:
                'https://www.tripadvisor.fr/Hotel_Review-g1122184-d1121862-Reviews-Hotel_du_Royans-Pont_en_Royan_Isere_Auvergne_Rhone_Alpes.html',
        },
        {
            title: 'Hôtel du col de la Machine',
            address: 'Avenue de la Forêt de Lente, 26190 Saint-Jean-en-Royans',
            image: hotelColMachine,
            url:
                'https://www.tripadvisor.fr/Hotel_Review-g1596419-d2268373-Reviews-Hotel_du_Col_de_la_Machine-Saint_Jean_en_Royans_Drome_Auvergne_Rhone_Alpes.html?m=19905',
        },
        {
            title: 'Camping du Pont de Manne',
            address: 'Pont de Manne, 26190 Saint-Thomas-en-Royans',
            image: campingManne,
            url: 'https://www.campingdupontdemanne.com/',
        },
    ],
    contact: {
        main: {
            title: 'Joséphine Soufis & Théo Murgat',
            description: '24 Rue Maurice Bouchor, 69007 Lyon',
        },
        list: [
            {
                title: 'Agnès Soufis & Artistide Soufis',
                description: '395 Chemin des Cabinières, 26350 Crépol',
            },
            {
                title: 'Dominique Murgat',
                description: 'Quartier Binla, 26190 Echevis',
            },
            {
                title: 'Hélène Cinquini & Franck Cinquini',
                description: 'Grande Rue, 38680 Pont-en-Royans',
            },
        ],
    },
};
