import React, { useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import config from '../../config/config';
import Link from '../Link';
import MenuButton from './MenuButton';
import { MEDIA_QUERIES } from '../../utils/breakpoints';
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../constants';

const StyledHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${MOBILE_HEADER_HEIGHT}px;
    padding: 0 40px;
    width: 100%;
    position: fixed;
    z-index: 1;
    font-size: 24px;
    background-color: white;

    ${MEDIA_QUERIES.tablet} {
        height: ${HEADER_HEIGHT}px;
        padding: 0 64px;
    }
`;

const HomeLink = styled(GatsbyLink)`
    text-decoration: none;
    color: inherit;
    letter-spacing: 4px;
`;

const MenuLink = styled(Link)`
    margin: 16px;

    ${MEDIA_QUERIES.tablet} {
        margin: 0 16px;
    }
`;

const MobileMenuButton = styled(MenuButton)`
    ${MEDIA_QUERIES.tablet} {
        display: none;
    }
`;

const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    position: fixed;
    top: ${MOBILE_HEADER_HEIGHT}px;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
    padding: 32px 0;
    transform: scaleY(0);
    transform-origin: top;

    &.open {
        opacity: 1;
        transform: scaleY(1);
    }

    ${MEDIA_QUERIES.tablet} {
        display: block;
        position: static;
        height: auto;
        opacity: 1;
        padding: 0;
        transform: unset;
    }
`;

const Header = () => {
    const { initials, listUrl } = config;
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuButtonClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const globalStyles = css`
        body {
            overflow: ${isMenuOpen ? 'hidden' : 'auto'};
        }
    `;

    return (
        <StyledHeader>
            <Global styles={globalStyles} />
            <div>
                <HomeLink to="/">
                    {initials[0]} / {initials[1]}
                </HomeLink>
            </div>
            <div>
                <MobileMenuButton isOpen={isMenuOpen} onClick={handleMenuButtonClick} />
                <Nav className={isMenuOpen && 'open'}>
                    <MenuLink to="/rsvp">Répondre</MenuLink>
                    <MenuLink to="/housing">Logement</MenuLink>
                    <MenuLink href={listUrl} target="_blank">
                        Liste de mariage
                    </MenuLink>
                    <MenuLink to="/contact">Contact</MenuLink>
                </Nav>
            </div>
        </StyledHeader>
    );
};

export default Header;
