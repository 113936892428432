import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CloseIcon from './CloseIcon';
import video from '../../assets/waltz.mp4';
import waltz from '../../assets/waltz.mp3';

const Video = styled.video`
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 999;
`;

const CloseButton = styled.button`
    position: fixed;
    top: 32px;
    right: 32px;
    z-index: 9999;
    cursor: pointer;
    background: none;
    border: none;
`;

const Waltz = ({ onClose }) => {
    useEffect(() => {
        const audio = new Audio(waltz);
        audio.play();

        return () => audio.pause();
    });

    return (
        <>
            <Video src={video} autoPlay loop />
            <CloseButton onClick={onClose} aria-label="Close">
                <CloseIcon />
            </CloseButton>
        </>
    );
};

Waltz.propTypes = {
    onClose: PropTypes.func,
};

export default Waltz;
