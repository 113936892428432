import { useEffect, useState } from 'react';
import tinykeys from 'tinykeys';

const useKonami = () => {
    const [showEasterEgg, setShowEasterEgg] = useState(false);

    useEffect(() => {
        const unsubscribe = tinykeys(window, {
            'ArrowUp ArrowUp ArrowDown ArrowDown ArrowLeft ArrowRight ArrowLeft ArrowRight b a': () => {
                setShowEasterEgg(true);
            },
        });

        return () => {
            unsubscribe();
        };
    });

    return [showEasterEgg, setShowEasterEgg];
};

export default useKonami;
