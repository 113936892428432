import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link as GatsbyLink } from 'gatsby';

const linkStyles = css`
    color: inherit;
    text-decoration: none;
    padding-bottom: 4px;
    background: linear-gradient(currentColor 0 0) var(--p, 100%) 100% / var(--d, 0) 2px no-repeat;
    transition: 0.3s, background-position 0s 0.3s;

    &:hover {
        --d: 100%;
        --p: 0%;
    }
`;

const InternalLink = styled(GatsbyLink)(linkStyles);
const LinkStyled = styled.a(linkStyles);

const Link = ({ children, className, href, target, to, ...props }) => {
    const rel = target === '_blank' ? 'noopener noreferrer' : '';

    if (to) {
        return (
            <InternalLink to={to} className={className} {...props}>
                {children}
            </InternalLink>
        );
    }

    return (
        <LinkStyled href={href} target={target} rel={rel} className={className} {...props}>
            {children}
        </LinkStyled>
    );
};

Link.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    to: PropTypes.string,
};

export default Link;
