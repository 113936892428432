import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const BAR_HEIGHT = 4;
const BUTTON_HEIGHT = 32;

const ButtonStyled = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    width: 40px;
    height: ${BUTTON_HEIGHT}px;
    padding: 0;
`;

const MenuBar = styled.div`
    height: ${BAR_HEIGHT}px;
    background: black;
    position: absolute;
    width: 100%;
    top: calc(50% - ${BAR_HEIGHT / 2}px);
    transition: transform 200ms ease-in-out;

    &:first-of-type {
        top: 0;

        &.open {
            transform: translateY(${(BUTTON_HEIGHT - BAR_HEIGHT) / 2}px) scale(0);
        }
    }

    &:last-of-type {
        bottom: 0;
        top: unset;

        &.open {
            transform: translateY(-${(BUTTON_HEIGHT - BAR_HEIGHT) / 2}px) scale(0);
        }
    }

    &.open:nth-of-type(2) {
        transform: rotate(45deg);
    }

    &.open:nth-of-type(3) {
        transform: rotate(-45deg);
    }
`;

const MenuButton = ({ className, isOpen, onClick }) => (
    <ButtonStyled className={className} onClick={onClick} type="button" aria-label="menu">
        <MenuBar className={isOpen && 'open'} />
        <MenuBar className={isOpen && 'open'} />
        <MenuBar className={isOpen && 'open'} />
        <MenuBar className={isOpen && 'open'} />
    </ButtonStyled>
);

MenuButton.propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
};

export default MenuButton;
