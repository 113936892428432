import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import Header from './Header';
import Footer from './Footer';
import siteConfig from '../../../gatsby-config';
import { MEDIA_QUERIES } from '../../utils/breakpoints';
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../constants';
import useKonami from '../waltz/useKonami';
import Waltz from '../waltz/Waltz';
import Background from './Background';

const {
    siteMetadata: { title, description },
} = siteConfig;

const App = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const Main = styled.main`
    flex-grow: 1;
    padding-top: ${MOBILE_HEADER_HEIGHT}px;

    ${MEDIA_QUERIES.tablet} {
        padding-top: ${HEADER_HEIGHT}px;
    }
`;

const Layout = ({ children }) => {
    const [isWaltzPlaying, setIsWaltzPlaying] = useKonami();

    const handleClose = useCallback(() => setIsWaltzPlaying(false), [setIsWaltzPlaying]);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="robots" content="noindex,nofollow" />
                <html lang="fr" />
            </Helmet>
            <App>
                <Header />
                <Main>{children}</Main>
                <Background />
                <Footer />
            </App>
            {isWaltzPlaying && <Waltz onClose={handleClose} />}
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node,
};

export default Layout;
